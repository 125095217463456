// Utilities
@import "assets/scss/utilities/mixins";
@import "assets/scss/utilities/variables";
@import "../../../node_modules/foundation-sites/scss/util/_unit.scss";
@import "../../../node_modules/foundation-sites/scss/util/_breakpoint.scss";

$breakpoints: (
    small: 0,
    medium: 640px,
    large: 1024px,
    xlarge: 1200px,
    xxlarge: 1440px,
);

.flexbackground {
    position: relative;

    // Section width

    &.constrain-width {
        .flexbackground__background {
            z-index: 0;
        }

        .flexbackground__content {
            border-radius: 8px;
            box-shadow: $herbal-shadow-5;
            overflow: hidden;

            @include breakpoint( small only ) {
                border-radius: 0;   
            }

            @at-root {
                .flexbackground.remove-background-gradient .flexbackground__content[class] {
                    box-shadow: none !important;
                }
            }
        }

        .flexbackground__content-container {
            z-index: 1;
            position: relative;
        }
    }


    // Content

    &__content-container {
        @include headers {
            &.invisible {
                @include breakpoint( small only ) {
                    margin-bottom: 0;
                    height: rem-calc(20px);
                }
            }
        }
    }

    &__background {
        background-position: center center;
        background-size: cover;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__content {
        // @include padding;
        padding-top: rem-calc(30);
        padding-bottom: rem-calc(30);
        position: relative;

        // @include xy-grid-container;
        padding-right: 0.625rem;
        padding-left: 0.625rem;
        max-width: 75rem;
        margin-left: auto;
        margin-right: auto;
        
        // @include z-index("above-overlay");
        z-index: 20;
        position: relative;

        @include breakpoint( small only ) {
            padding-top: rem-calc(48);
            padding-bottom: rem-calc(48);
        }
       
        @media screen and ( min-width: map-get( $breakpoints, 'medium' ) ) {
            padding-top: rem-calc(45);  
            padding-bottom: rem-calc(45);
          
        }
            
        @media screen and ( min-width: map-get( $breakpoints, 'large' ) ) {
            padding-top: rem-calc(60);
            padding-bottom: rem-calc(60);
        }

        @at-root {
            .flexbackground.has-columns .flexbackground__content {
                padding-bottom: rem-calc(40);
            }
        }

        @media print, screen and ( min-width: 40em ) {
            padding-right: 0.9375rem;
            padding-left: 0.9375rem; 
        }

        // WYSI Text

        p {
            &:last-child {
                @include breakpoint( small only ) {
                    margin-bottom: 0;   
                }

                a { margin-bottom: 0; }

                a:only-child {
                    @include breakpoint( small only ) {
                        width: 100%;
                        display: block;
                        margin-bottom: 0;   
                    }
                }
            }
        }

        
        // Height

        .height-medium {
            min-height: rem-calc(400);

            @media screen and ( min-width: map-get( $breakpoints, 'large' ) ) {
                min-height: rem-calc(600);
            }
        }

        .height-large {
            min-height: rem-calc(600);

            @media screen and ( min-width: map-get( $breakpoints, 'large' ) ) {
                min-height: rem-calc(800);
            }
        }

        .height-full {
            min-height: 100vh;
        }

        // Align text to left on small when using align right in wysiwyg
        *[style*="text-align: right"] {
            @media screen and ( max-width: map-get( $breakpoints, 'medium' ) ) {
                text-align: left !important; // override wysiwyg inline styles
            }
        }

    }

    
    // Set text color if the dark overlay is present
    .overlay:not(.overlay-color-primary) ~ .flexbackground__content .cell * {
        color: #fff;
    }

    
    // Content Columns

    &__content-columns {}

    &__column-content {
        @at-root {
            .flexbackground.background-gradient .flexbackground__column-content {
                * {
                    color: $true-white;
                }
            }
        }

        br {
            @media screen and (max-width: 640px) {
                display: none;
            }
        }
    }
}
